<template >
  <section v-if="listData.items.length > 0" class="advantages-list">
    <!-- <b-container> -->
    <!-- Advantages -->
    <h3 class="advantages-list__title">
      {{ $t(`label.${listData.title}`) }}
    </h3>
    <b-row v-if="isService" class="justify-center">
      <b-col
        cols="12"
        lg="3"
        md="6"
        sm="12"
        v-for="(item, index) in listData.items"
        :key="index"
      >
        <section class="services">
          <!-- <b-col sm="12"> -->
          <b-card tag="article" class="card--services card--specific-service">
            <template>
              <section class="sub-service__img">
                <div class="sub-service__img--div">
                  <b-img
                    height="100"
                    fluid
                    :src="require('@/assets/imgs/idea.svg')"
                  ></b-img>
                </div>
              </section>

              <h4 class="card-title text-center mt-4">
                {{ item.title ? item.title : "-----" }}
              </h4>
            </template>
            <section class="card-text">
              <b-card-text>{{ item.name }}</b-card-text>
            </section>
          </b-card>
          <!-- </b-col> -->
        </section>
      </b-col>
    </b-row>
    <ul class="advantages-list__container" v-else>
      <li
        v-for="(item, index) in listData.items"
        :key="index"
        class="advantages-list__item"
      >
        {{ item.name }}
      </li>
    </ul>
    <!-- Advantages -->
    <!-- </b-container> -->
  </section>
</template>

<script>
export default {
  name: "AdvantagesList",
  props: {
    listData: {
      type: Object,
      default: () => {
        return {
          title: "features",
          items: [],
        };
      },
    },
    isService: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
